import { CSSProperties, HTMLProps, ReactNode } from "react";
import { useStorage } from "../../hooks";
import './PlatformViewProvider.css';
import { classNames } from "../../utils";
import { useLocation } from "react-router-dom";


interface PlatformViewProviderProps extends HTMLProps<HTMLDivElement> {
    children: ReactNode;
}

export const PlatformViewProvider = ({
    children
}: PlatformViewProviderProps) => {
    const { desktopMode } = useStorage()
    const loc = useLocation()
    return(
        <div
        className={
            classNames(
                'platform-provider',
                desktopMode ? 'platform-provider--desktop' : 'platform-provider--mobile',
                loc.pathname == '/rent_in_or_out' && !desktopMode && 'platform-provider--desktop--pink-pattern',
                // loc.pathname == '/inf' && 'platform-provider--desktop--pink-pattern',
                loc.pathname == '/quiz' && desktopMode && 'platform-provider--smallpaddings',
                loc.pathname == '/result' && desktopMode && 'platform-provider--ultrasmallpaddings'
            )
        }
        >
            {children}
        </div>
    )
}