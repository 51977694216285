


export const VKIcon = () => {
    return(
        <svg width="31" height="20" viewBox="0 0 31 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.5957 13.4956V1.45H17.0142V8.02528V8.80349L17.7881 8.72137C19.4844 8.54138 21.1398 7.51649 22.4811 6.12329C23.7128 4.84398 24.7336 3.2004 25.3252 1.45H28.7597C27.7665 5.2712 24.8417 8.1087 22.7272 9.31428L21.5465 9.98746L22.7802 10.5578C24.9005 11.538 28.2476 14.0258 29.7969 18.45H25.9421C24.6334 14.9649 21.6816 12.2606 17.7826 11.878L17.0142 11.8026V12.5746V18.4497C12.1334 18.4227 8.3688 16.7625 5.77977 13.7993C3.2682 10.9249 1.79776 6.74926 1.57238 1.45H5.28903C5.64809 9.60856 9.42288 13.3603 12.7281 14.1752L13.5957 14.3891V13.4956Z" 
        fill="currentColor" stroke="currentColor" stroke-width="1.4"/>
        </svg>
    )
}