import { BlockProps } from "../types"
import './BlockBigLeftTopLongCap.css'


export const BlockBigLeftTopLongCap = ({
    children,
    ...rest
}: BlockProps): JSX.Element => {
    return(
        <div
        className='custom-block'
        >
            {children &&
            <>
            <span
            className='custom-block__additional'
            style={{color: rest.style?.background as string || rest.style?.backgroundColor as string || 'currentcolor'}}
            >
                <svg width="370" height="40" viewBox="0 0 370 40" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 25.6C0 16.6392 0 12.1587 1.7439 8.73615C3.27787 5.72556 5.72556 3.27787 8.73615 1.7439C12.1587 0 16.6392 0 25.6 0H270.357C275.387 0 277.903 0 280.262 0.581876C282.354 1.09769 284.348 1.94799 286.168 3.09991C288.221 4.39935 289.963 6.21433 293.446 9.84427L296.443 12.9675C299.23 15.8714 300.623 17.3234 302.266 18.363C303.722 19.2845 305.317 19.9647 306.99 20.3774C308.878 20.8429 310.89 20.8429 314.915 20.8429H350.843C353.776 20.8429 355.242 20.8429 356.47 21.0346C363.343 22.1084 368.734 27.5001 369.808 34.3732C370 35.6006 370 37.0671 370 40H0V25.6Z" fill="currentColor"/>
                </svg>
            </span>
            <div
            {...rest}
            className='custom-block__body'
            >
                {children}
            </div>
            </>
            }
        </div>
    )
}