import { CSSProperties, ReactNode } from "react";
import { BlockProps } from "../types"
import { classNames } from "../../../utils";
import './BlockPartsTemplate.css'



interface BlockPartsTemplate extends BlockProps {
    svg: ReactNode;
    svgPosition: 'start' | 'end';
    classNameMain?: string;
    styleMain?: CSSProperties;
}

export const BlockPartsTemplate = ({
    classNameMain,
    children,
    svg,
    svgPosition,
    styleMain,
    ...rest
}: BlockPartsTemplate) => {
    return(
        <div
        className={
            classNames(
                'custom-block-template',
                classNameMain
            )
        }
        style={{...styleMain, flexDirection: svgPosition === 'start' ? 'column' : 'column-reverse'}}
        >
            {children &&
            <>
            <span
            className='custom-block-template__additional'
            style={{color: rest.style?.background as string || rest.style?.backgroundColor as string || 'currentcolor'}}
            >
                {svg}
            </span>
            <div
            {...rest}
            className={
                classNames(
                    'custom-block-template__body',
                    `custom-block-template__body--${svgPosition}`,
                    rest.className
                )
            }
            >
                {children}
            </div>
            </>
            }
        </div>
    )
}