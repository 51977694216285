import { HTMLProps, ReactNode } from "react";
import './IconCircle.css';
import { classNames } from "../../utils";


interface IconCircleProps extends HTMLProps<HTMLAnchorElement> {
    icon?: ReactNode;
}

export const IconCircle = ({
    icon,
    ...rest
}: IconCircleProps): JSX.Element => {
    return(
        <a
        {...rest}
        className={
            classNames(
                'icon-circle',
                rest.className
            )
        }
        >
            {icon}
        </a>
    )
}

interface IconCircleRowProps extends HTMLProps<HTMLDivElement> {
    icons?: ReactNode[];
}

export const IconCircleRow = ({
    icons,
    ...rest
}: IconCircleRowProps) => {
    return(
        <div
        {...rest}
        className={
            classNames(
                'icon-circle-row',
                rest.className
            )
        }
        >
            {icons?.map(icon=>icon)}
        </div>
    )
}