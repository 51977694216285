import img1 from './1.webp'
import img2 from './2.webp'
import img3 from './3.webp'
import img4 from './4.webp'
import img5 from './5.webp'
import img6 from './6.webp'
import img7 from './7.webp'
import img8 from './8.webp'
import img9 from './9.webp'
import img10 from './10.webp'
import img11 from './11.webp'

export const activities_images: {[key: string]: string} = {
    "Скроллинг видео": img1,
    "Бёрд-вотчинг": img2,
    "Онлайн-шопинг": img3,
    "Хобби-хорсинг": img4,
    "Разобрать балкон": img5,
    "Изучать языки": img6,
    "Йо-йо": img7,
    "Просмотр улиц в картах": img8,
    "Карвинг по овощам": img9,
    "Пазлы": img10,
    "Бонсай": img11
}