import { Result } from "../hoc/StorageProvider/types";
import { server } from "./config";



export async function returnResult(city: string, result: string): Promise<void> {
    const params = {city, result}
    const queryString = new URLSearchParams(params).toString();
    const request = await fetch(`${server}/v1/stat/new_result?${queryString}`)
    if (!request.ok){
        throw new Error('Не удалось отправить результат')
    }
}

export async function getStat(city: string, result: string): Promise<Result> {
    var params = {city, result};
    const queryString = new URLSearchParams(params).toString();
    const request = await fetch(`${server}/v1/stat/get?${queryString}`);
    if (!request.ok){
        throw new Error('Не удалось получить статистику')
    }

    const response: Result = await request.json() 
    return response
}