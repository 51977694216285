import { HTMLProps, ReactNode } from "react";
import { classNames } from "../../utils";
import './TextWithHeader.css'
import { text } from "stream/consumers";


interface TextWithHeaderProps extends HTMLProps<HTMLDivElement> {
    header?: ReactNode;
    textAlign?: 'left' | 'center';
    justify?: boolean;
    children?: ReactNode;
}

export const TextWithHeader = ({
    header,
    children,
    textAlign = 'center',
    justify = false,
    ...rest
}: TextWithHeaderProps): JSX.Element => {
    return(
        <div
        {...rest}
        className={
            classNames(
                'text-with-header',
                rest.className,
                textAlign === 'left' && 'text-with-header--leftalign'
            )
        }
        >
            {header &&
                <div
                className='text-with-header__header'
                >
                    {header}
                </div>
            }
            <div
            className={
                classNames(
                    'text-with-header__caption',
                    justify && 'text-with-header__caption--justify'
                )
            }
            >
                {children}
            </div>
        </div>
    )
}