import { HTMLProps, ReactNode } from "react";
import { classNames } from "../../utils";
import './StretchedBox.css';


interface StretchedBoxProps extends HTMLProps<HTMLDivElement> {
    gap?: number | string;
    children: ReactNode;
}

export const StretchedBox = ({
    gap = 0,
    children,
    ...rest
}: StretchedBoxProps): JSX.Element => {
    return(
        <div 
        {...rest}
        className={
            classNames(
                "stretched-box",
                rest.className
            )
        }
        style={{
            gap: gap,
            ...rest.style
        }}
        >
            {children}
        </div>
    )
}