import { HTMLProps, useEffect, useState } from "react";
import './ScrollSelect.css';
import { classNames } from "../../utils";

interface ScrollSelectProps extends Omit<HTMLProps<HTMLDivElement>, 'onChange'> {
    variables?: string[];
    onChange: (value: number) => void;
}

export const ScrollSelect = ({
    variables = [],
    onChange,
    ...rest
}: ScrollSelectProps): JSX.Element => {

    const [selectedValue, setSelectedValue] = useState<number>(0)
    
    const one_value_size = 100/variables.length;

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
        const scrollableHeight = scrollHeight - clientHeight;
        const percentage = (scrollTop / scrollableHeight) * 100;
        const n = Math.max(1, Math.min(variables.length, Math.floor(percentage/one_value_size)))
        setSelectedValue(n-1)
    };

    useEffect(()=>{
        onChange(selectedValue)
    }, [selectedValue])

    return(
        <div
        {...rest}
        className={
            classNames(
                'scroll-select',
                rest.className
            )
        }
        onScroll={handleScroll}
        >
            <div
            className='scroll-select__items bold-font'
            >
                {variables.map((value, i)=>
                    <div
                    className={
                        classNames(
                            'scroll-select__item',
                            selectedValue == i && 'scroll-select__item--selected'
                        )
                    }
                    >
                        {value}
                    </div>
                )}
            </div>
        </div>
    )
}