import { BlockProps } from "../types";

export const BlockCenterTopCap = ({
    children,
    ...rest
}: BlockProps): JSX.Element => {
    return(
        <>
        <svg className="clippy-base">
        <defs>
            <clipPath id="clip-block_center_top_cap" clipPathUnits="objectBoundingBox">
            <path d="M0,0.122 C0,0.096,0,0.083,0.005,0.073 C0.009,0.064,0.015,0.057,0.024,0.053 C0.033,0.047,0.045,0.047,0.069,0.047 H0.157 C0.165,0.047,0.169,0.047,0.173,0.047 C0.176,0.046,0.179,0.045,0.183,0.043 C0.186,0.042,0.189,0.039,0.196,0.035 L0.226,0.013 C0.232,0.008,0.235,0.006,0.239,0.004 C0.242,0.003,0.245,0.001,0.249,0.001 C0.253,0,0.257,0,0.265,0 H0.737 C0.748,0,0.754,0,0.759,0.001 C0.764,0.002,0.769,0.004,0.773,0.007 C0.778,0.009,0.783,0.013,0.791,0.021 L0.803,0.031 C0.81,0.037,0.813,0.04,0.817,0.042 C0.821,0.044,0.824,0.046,0.828,0.046 C0.833,0.047,0.837,0.047,0.846,0.047 H0.931 C0.955,0.047,0.967,0.047,0.976,0.053 C0.985,0.057,0.991,0.064,0.995,0.073 C1,0.083,1,0.096,1,0.122 V0.926 C1,0.952,1,0.965,0.995,0.975 C0.991,0.983,0.985,0.99,0.976,0.995 C0.967,1,0.955,1,0.931,1 H0.069 C0.045,1,0.033,1,0.024,0.995 C0.015,0.99,0.009,0.983,0.005,0.975 C0,0.965,0,0.952,0,0.926 V0.122" fill="#F8D849"/>
            </clipPath>
        </defs>
        </svg>
        <div
        {...rest}
        style={{clipPath:"url(#clip-block_center_top_cap)", ...rest.style}}
        >
            {children}
        </div>
        </>
    )
}