import { BlockProps } from "../types";

export const BlockRightTopCap = ({
    children,
    ...rest
}: BlockProps): JSX.Element => {
    return(
        <>
        <svg className="clippy-base">
        <defs>
            <clipPath id="clip-block_right_top_cap" clipPathUnits="objectBoundingBox">
            <path d="M0,0.115 C0,0.094,0,0.084,0.005,0.076 C0.009,0.069,0.015,0.064,0.024,0.06 C0.033,0.056,0.045,0.056,0.069,0.056 H0.559 C0.572,0.056,0.579,0.056,0.585,0.055 C0.59,0.054,0.596,0.052,0.6,0.049 C0.606,0.047,0.611,0.043,0.62,0.035 L0.639,0.018 C0.647,0.012,0.65,0.009,0.655,0.007 C0.659,0.005,0.663,0.003,0.667,0.002 C0.672,0.001,0.678,0.001,0.688,0.001 H0.931 C0.955,0.001,0.967,0.001,0.976,0.005 C0.985,0.009,0.991,0.014,0.995,0.021 C1,0.029,1,0.039,1,0.06 V0.942 C1,0.963,1,0.973,0.995,0.981 C0.991,0.988,0.985,0.994,0.976,0.997 C0.967,1,0.955,1,0.931,1 H0.069 C0.045,1,0.033,1,0.024,0.997 C0.015,0.994,0.009,0.988,0.005,0.981 C0,0.973,0,0.963,0,0.942 V0.115" fill="#F8D849"/>
            </clipPath>
        </defs>
        </svg>
        <div
        {...rest}
        style={{clipPath:"url(#clip-block_right_top_cap)", ...rest.style}}
        >
            {children}
        </div>
        </>
    )
}