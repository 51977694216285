import { forwardRef } from "react";
import { BlockProps } from "../types";

export const BlockSmallCenterCap = forwardRef<HTMLDivElement, BlockProps>(({
    children,
    ...rest
}, ref): JSX.Element => {
    return(
        <>
        <svg className="clippy-base">
        <defs>
            <clipPath id="clip-block_small_center_cap" clipPathUnits="objectBoundingBox">
            <path d="M0,0.392 C0,0.308,0,0.266,0.005,0.233 C0.009,0.205,0.015,0.182,0.024,0.167 C0.033,0.151,0.045,0.151,0.069,0.151 H0.157 C0.165,0.151,0.169,0.151,0.173,0.149 C0.176,0.147,0.179,0.143,0.183,0.138 C0.186,0.133,0.189,0.125,0.196,0.11 L0.226,0.041 C0.232,0.025,0.236,0.018,0.239,0.013 C0.242,0.008,0.245,0.004,0.249,0.002 C0.253,0,0.256,0,0.264,0 H0.737 C0.748,0,0.754,0,0.759,0.004 C0.764,0.007,0.769,0.013,0.773,0.021 C0.778,0.029,0.782,0.042,0.791,0.066 L0.803,0.098 C0.81,0.118,0.813,0.127,0.817,0.134 C0.821,0.141,0.824,0.145,0.828,0.148 C0.832,0.151,0.837,0.151,0.846,0.151 H0.931 C0.955,0.151,0.967,0.151,0.976,0.167 C0.985,0.182,0.991,0.205,0.995,0.233 C1,0.266,1,0.308,1,0.392 V0.758 C1,0.843,1,0.885,0.995,0.918 C0.991,0.946,0.985,0.969,0.976,0.984 C0.967,1,0.955,1,0.931,1 H0.069 C0.045,1,0.033,1,0.024,0.984 C0.015,0.969,0.009,0.946,0.005,0.918 C0,0.885,0,0.843,0,0.758 V0.392" fill="#F8D849"/>
            </clipPath>
        </defs>
        </svg>
        <div
        {...rest}
        style={{clipPath:"url(#clip-block_small_center_cap)", ...rest.style}}
        ref={ref}
        >
            {children}
        </div>
        </>
    )
})