import { CSSProperties, HTMLProps, ReactNode } from "react";
import './Panel.css';
import { classNames } from "../../utils";


interface PanelProps extends HTMLProps<HTMLDivElement> {
    oneScreenMode?: boolean;
    imageCover?: string;
    popout?: ReactNode;
    children?: ReactNode;
}

export const Panel = ({
    oneScreenMode = false,
    imageCover,
    popout,
    children,
    ...rest
}: PanelProps): JSX.Element => {
    const styles: CSSProperties = {
        backgroundImage: (imageCover ? `url(${imageCover})` : undefined),
    }
    return(
        <div
        {...rest}
        className={
            classNames(
                'panel',
                oneScreenMode && 'panel--onescreen',
                imageCover && 'panel--imagecover',
                rest.className
            )
        }
        style={{...styles, ...rest.style}}
        >
            {children}
            {popout && 
            <div className='popout-cover'>
                {popout}
            </div>
            }
        </div>
    )
}