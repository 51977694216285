import { FC, useEffect } from "react"
import { BlockPartsTemplate, BottomGradient, PaddingDiv, Panel, StretchedBox, TextWithHeader } from "../../components"
import { Button } from "../../components/Button"
import { BlockCenterTopCap } from "../../components/CustomBlocks/BlockCenterTopCap"
import { useNavigate } from "react-router-dom"
import { ViewImage } from "./img"
import { useStorage } from "../../hooks"
import './AnswerUserRenting.css'
import { ym } from "../../ym"

const background = "https://sun9-79.userapi.com/impg/Wy0PYDWloN6MGugVIdkGudW-CwAZxOOg_WTpCA/b5XXHTw8XOk.jpg?size=774x1080&quality=95&sign=dff01fc7a2422c754e79a18fc64d9b23&type=album"


const BlockAdditional = () => (
    <svg width="370" height="40" viewBox="0 0 370 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 40C0 32.5449 0 28.8174 1.21793 25.8771C2.84183 21.9566 5.95662 18.8418 9.87707 17.2179C12.8174 16 16.5449 16 24 16H58.2489C61.13 16 62.5705 16 63.9634 15.7517C65.1998 15.5313 66.4059 15.1661 67.5568 14.6637C68.8535 14.0976 70.0521 13.2986 72.4493 11.7005L83.5507 4.29951C85.9479 2.70141 87.1465 1.90236 88.4432 1.3363C89.5941 0.833863 90.8002 0.468679 92.0366 0.248293C93.4295 0 94.87 0 97.7511 0H272.775C276.935 0 279.016 0 281.005 0.408351C282.77 0.770639 284.479 1.36984 286.083 2.18968C287.892 3.11377 289.517 4.41324 292.765 7.0122L296.988 10.3902C299.587 12.4694 300.886 13.509 302.333 14.2483C303.617 14.9041 304.984 15.3835 306.396 15.6733C307.988 16 309.652 16 312.98 16H346C353.455 16 357.183 16 360.123 17.2179C364.043 18.8418 367.158 21.9566 368.782 25.8771C370 28.8174 370 32.5449 370 40V40H0V40Z" fill="currentColor"/>
    </svg>
)

export const AnswerUserRentingOrRentingOut: FC = () => {

    const {setUserMode, desktopMode} = useStorage()
    const go = useNavigate()


    const next = (mode: '0' | '1') => {
        setUserMode(mode)
        if(mode == '1'){
            go(`/inf`)
        }else{
            go('/quiz')
        }
    }

    return(
        <Panel
        oneScreenMode
        style={{backgroundColor:'#E8DDFF', padding: 0}}
        >
            <div
            className='preview-screen preview-screen--rent'
            >
                <div
                className="preview-screen__background preview-screen__background--rent"
                >
                    <div
                    className="preview-screen__background__biglogo--rent"
                    >
                        <ViewImage />
                    </div>
                </div>
                <div
                className="preview-screen__bottom preview-screen__bottom--rent"
                >
                    <BlockPartsTemplate
                    style={{background: desktopMode ? 'var(--app-color-card-purple)' : "white"}}
                    svgPosition="start"
                    svg={<BlockAdditional/>}
                    styleMain={{marginBottom: 12}}
                    >
                        <PaddingDiv
                        horizontal={20}
                        top={8}
                        bottom={12}
                        style={{width:'100%', height:'100%', boxSizing:'border-box'}}
                        >
                            <StretchedBox 
                            gap={8}
                            style={{
                                paddingBottom: 8
                            }}
                            >
                            <div
                            style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingBottom: 16}}
                            >
                                <TextWithHeader
                                textAlign="left"
                                // justify
                                // style={{padding:'0 12px'}}
                                >
                                    Время — самый ценный ресурс, не так ли?<br/><br/>
                                    Так чем его занять, когда наконец‑то появилась возможность выдохнуть? <br/><br/>
                                    Выбирайте между вариантами серьёзных и не очень дел, чтобы найти себе занятие по душе.
                                    Кстати, вы снимаете или сдаёте квартиру?
                                </TextWithHeader>
                            </div>
                            <Button 
                            stretched 
                            color='purple'
                            onClick={()=>{
                                next('1')
                                ym('prssIAmOwnerArenda')
                            }}
                            >Сдаю</Button>
                            <Button 
                            stretched 
                            color='purple'
                            onClick={()=>{
                                next('0')
                                ym('prssIAmLiverArenda')
                            }}
                            >Арендую</Button>
                        </StretchedBox>
                        </PaddingDiv>
                        </BlockPartsTemplate>
                </div>
            </div>
        </Panel>
    )
}