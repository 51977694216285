import { HTMLProps } from "react";
import { classNames } from "../../utils";
import './PartedProgress.css';


interface PartedProgressProps extends HTMLProps<HTMLDivElement> {
    parts?: number;
    level?: number;
}

export const PartedProgress = ({
    parts=10,
    level=1,
    ...rest
}: PartedProgressProps): JSX.Element => {

    return(
        <div
        {...rest}
        className={
            classNames(
                'parted-progress',
                rest.className
            )
        }
        >
            {Array.from({ length: parts }, (_, index) => (
                <span 
                className={
                    classNames(
                        "parted-progress__item",
                        (index+1) <= level && "parted-progress__item--active"
                    )
                } 
                />
            ))}
        </div>
    )
}