import { forwardRef } from "react";
import { BlockProps } from "../types";



export const BlockBigScreenPart = forwardRef<HTMLDivElement, BlockProps>(({
    children,
    ...rest
}, ref): JSX.Element => {

    return(
        <>
        <svg className="clippy-base">
        <defs>
            <clipPath id="clip-block_big_screen_part" clipPathUnits="objectBoundingBox">
            <path d="M0,0.043 C0,0.028,0,0.02,0.005,0.015 C0.009,0.01,0.015,0.005,0.024,0.003 C0.033,0,0.045,0,0.069,0 H0.931 C0.955,0,0.967,0,0.976,0.003 C0.985,0.005,0.991,0.01,0.995,0.015 C1,0.02,1,0.028,1,0.043 V0.957 C1,0.972,1,0.98,0.995,0.985 C0.991,0.99,0.985,0.995,0.976,0.997 C0.967,1,0.955,1,0.931,1 H0.856 C0.847,1,0.843,1,0.839,0.999 C0.835,0.999,0.831,0.998,0.828,0.997 C0.824,0.996,0.821,0.995,0.814,0.991 L0.794,0.982 C0.787,0.979,0.784,0.977,0.78,0.976 C0.777,0.975,0.773,0.974,0.769,0.974 C0.765,0.973,0.761,0.973,0.752,0.973 H0.253 C0.244,0.973,0.24,0.973,0.236,0.974 C0.232,0.974,0.229,0.975,0.225,0.976 C0.221,0.977,0.218,0.979,0.211,0.982 L0.192,0.991 C0.185,0.995,0.181,0.996,0.177,0.997 C0.174,0.998,0.17,0.999,0.167,0.999 C0.163,1,0.158,1,0.15,1 H0.069 C0.045,1,0.033,1,0.024,0.997 C0.015,0.995,0.009,0.99,0.005,0.985 C0,0.98,0,0.972,0,0.957 V0.043" fill="#F8D849"/>
            </clipPath>
        </defs>
        </svg>
        <div
        {...rest}
        style={{transition:'width 1s ease', clipPath:"url(#clip-block_big_screen_part)", ...rest.style}}
        ref={ref}
        >
            {children}
        </div>
        </>
    )
})