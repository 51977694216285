import { ReactNode, useEffect, useState } from "react";
import { createContext } from "react";
import { AppStorage, Result } from "./types";
import { getStat, returnResult } from "../../api";

//@ts-ignore
export const storageContext = createContext<AppStorage>(null)

interface ProviderProps {
    children: ReactNode;
}


export const StorageProvider = ({
    children
}: ProviderProps): JSX.Element => {

    const [city, setCity] = useState<string | null>(null)
    const [result, setResult] = useState<Result | null>(null)
    const [currentChoice, setCurrentChoice] = useState<string | null>("Скроллинг видео")
    const [userMode, setUserMode] = useState<"0" | "1">("1")
    const [desktopMode, setDesktopMode] = useState<boolean>(false)

    useEffect(()=>{
        const onScreenSizeHandler = () => {
            const height = window.innerHeight;
            const width = window.innerWidth;

            if (width > 600) {
                document.body.setAttribute('bg-pattern', 'true');
                if(!desktopMode){
                    setDesktopMode(true)
                }
            } else {
                document.body.setAttribute('bg-pattern', 'false');
                if(desktopMode){
                    setDesktopMode(false)
                }
            }
        }

        onScreenSizeHandler()
        window.addEventListener('resize', onScreenSizeHandler)

        return () => window.removeEventListener('resize', onScreenSizeHandler)
    }, [desktopMode])

    const fetchResult = async () => {
        if (!(city && currentChoice)) return ;
       
        const response = await getStat(city, currentChoice)
        if (!response) return;

        setResult(response)
    }

    const saveChoice = async () => {
        if (!(city && currentChoice)) return ;

        await returnResult(city, currentChoice)
        .catch((err)=>console.log(err))
    }

    const values = {
        city, result, currentChoice,
        desktopMode,
        userMode
    }
    const calls = {
        setCity, setCurrentChoice,
        fetchResult, saveChoice,
        setUserMode
    }

    return(
        <storageContext.Provider value={{...values, ...calls}}>
            {children}
        </storageContext.Provider>
    )
}