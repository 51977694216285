import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { Panel, BottomGradient } from './components/';
import { AnswerUserRentingOrRentingOut, PreQuizScreen, PreResultsLoading, PreviewLoading, Promocode, Quiz, Results, SelectCity } from './screens';
import { PlatformViewProvider } from './hoc';
import { useEffect, useState } from 'react';
const FontFaceObserver = require('fontfaceobserver');

function App() {

  const [fontsLoad, setFontsLoad] = useState(false)
  const go = useNavigate()

  useEffect(()=>{
    go('/', {replace: true})
  }, [])


  useEffect(()=>{
    async function fetchFont() {
      var font = new FontFaceObserver('Halvar Breitschrift')
      await font.load()
      .then(()=>{
        console.log('Шрифт загружен')
        setFontsLoad(true)
      })
    }

    fetchFont()
  }, [])

  if (!fontsLoad) {
    return null
  }

  return (
    <PlatformViewProvider>
      <Routes>
        <Route path='/' element={<PreviewLoading/>} />
        <Route path='/rent_in_or_out' element={<AnswerUserRentingOrRentingOut />} />
        <Route path='/inf' element={<PreQuizScreen />} />
        <Route path='/select_city' element={<SelectCity />} />
        <Route path='/preresult' element={<PreResultsLoading />} />
        <Route path='/promocode' element={<Promocode />} />
        <Route path='/result' element={<Results />} />
        <Route path='/quiz' element={<Quiz />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </PlatformViewProvider>
  );
}

export default App;
