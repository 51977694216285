


export const OKIcon = () => {
    return(
        <svg width="21" height="36" viewBox="0 0 21 36" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.50029 9.17487C2.50029 4.79819 6.04838 1.25005 10.4253 1.25005C14.8022 1.25005 18.3503 4.79819 18.3503 9.17487C18.3503 13.5517 14.8022 17.1001 10.4253 17.1001C6.04841 17.1001 2.50029 13.5517 2.50029 9.17487ZM6.15991 9.175C6.15991 11.5306 8.06961 13.4404 10.4253 13.4404C12.781 13.4404 14.6907 11.5306 14.6907 9.175C14.6907 6.81935 12.781 4.90969 10.4253 4.90969C8.06964 4.90969 6.15991 6.81935 6.15991 9.175Z" fill="currentColor" stroke="currentColor" stroke-width="1.4"/>
        <path d="M13.7413 24.9442L12.4583 25.2056L13.3883 26.1273L18.7403 31.4316C18.7405 31.4319 18.7408 31.4322 18.7411 31.4324C19.4081 32.0993 19.4087 33.1807 18.7422 33.8483C18.0753 34.5162 16.9935 34.5173 16.3254 33.8502L16.3254 33.8502L16.3191 33.8441L11.1215 28.7826L10.6107 28.2852L10.1227 28.805L5.40535 33.8302C5.07035 34.176 4.62559 34.3499 4.17795 34.3499C3.74985 34.3499 3.3237 34.1911 2.99192 33.8712C2.31247 33.2161 2.29278 32.1343 2.94794 31.4548L2.9498 31.4529L8.03148 26.1421L8.91883 25.2147L7.6587 24.9709C4.16246 24.2944 2.05214 22.6413 1.99031 22.5917L1.9903 22.5917C1.254 22.0012 1.13573 20.9256 1.72622 20.1893L1.72623 20.1893C2.31657 19.453 3.39202 19.3347 4.12858 19.9251L4.12859 19.9251C4.26221 20.0308 6.72058 21.8356 10.6325 21.8383L10.6334 21.8383C14.5481 21.8356 16.9594 20.0286 17.0896 19.9251C17.8261 19.3347 18.9016 19.453 19.4919 20.1893L20.038 19.7514L19.4919 20.1893C20.0824 20.9256 19.9642 22.0011 19.228 22.5916C19.1637 22.6431 17.1392 24.252 13.7413 24.9442Z" fill="currentColor" stroke="currentColor" stroke-width="1.4"/>
        <path d="M10.6332 21.1378C10.6293 21.1377 10.6312 21.1376 10.6332 21.1376L10.6332 21.1378ZM10.6332 21.1378C10.637 21.1377 10.6351 21.1376 10.6332 21.1376L10.6332 21.1378ZM10.6332 21.1375C10.6297 21.1375 10.6307 21.1375 10.6324 21.1375C10.6326 21.1375 10.6329 21.1375 10.6332 21.1376L10.6332 21.1375ZM10.6332 21.1376C10.6334 21.1375 10.6337 21.1375 10.634 21.1375C10.6356 21.1375 10.6366 21.1375 10.6332 21.1375L10.6332 21.1376Z" fill="currentColor" stroke="currentColor" stroke-width="1.4"/>
        </svg>
    )
}