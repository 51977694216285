import { FC, useRef, useState, Fragment, PropsWithChildren, useEffect, useMemo } from "react";
import { BlockBigScreenPart, BlockPartsTemplate, BlockSmallCenterCap, Button, Panel, ScrollSelect, TextWithHeader } from "../../components";
import './SelectCity.css';
import { useStorage } from "../../hooks";
import { useNavigate, useNavigation } from "react-router-dom";
import { BlockGreatDirDesktop } from "../../components/CustomBlocks/BlockGreatDirDesktop/BlockGreatDirDesktop";
import { ScreenSpinner } from "../../components/ScreenSpinner/ScreenSpinner";
import { ym } from "../../ym";

const cities = ['Москва',
    'Санкт-Петербург',
    'Екатеринбург',
    'Новосибирск',
    'Ростов-на-Дону',
    'Краснодар',
    'Нижний Новгород',
    'Самара',
    'Омск',
    'Красноярск',
    'Казань',
    'Воронеж',
    'Саратов',
    'Тюмень',
    'Уфа',
    'Сочи']


const MainBlockAdditional = () => (
    <svg width="370" height="40" viewBox="0 0 370 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 14.3998C0 23.3607 0 27.8411 1.7439 31.2637C3.27787 34.2743 5.72556 36.7219 8.73615 38.2559C12.1587 39.9998 16.6392 39.9998 25.6 39.9998H56.2489C59.13 39.9998 60.5705 39.9998 61.9634 39.7515C63.1998 39.5311 64.4059 39.166 65.5568 38.6635C66.8535 38.0975 68.0521 37.2984 70.4493 35.7003L75.1015 32.5988C79.8958 29.4026 82.2929 27.8045 84.8863 26.6724C87.1883 25.6675 89.6005 24.9372 92.0732 24.4964C94.8591 23.9998 97.7401 23.9998 103.502 23.9998H268.925C274.705 23.9998 277.595 23.9998 280.372 24.4932C283.773 25.0973 287.053 26.248 290.086 27.9006C292.563 29.2501 294.82 31.0556 299.333 34.6665C301.59 36.4719 302.719 37.3747 303.957 38.0494C305.473 38.8757 307.113 39.4511 308.814 39.7531C310.202 39.9998 311.647 39.9998 314.538 39.9998H344.4C353.361 39.9998 357.841 39.9998 361.264 38.2559C364.274 36.7219 366.722 34.2743 368.256 31.2637C370 27.8411 370 23.3607 370 14.3998V0H0V14.3998Z" fill="#E8DDFF"/>
    </svg>
)

const BottomBlockAdditional = () => (
    <svg width="370" height="40" viewBox="0 0 370 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 40.0002C0 32.5449 0 28.8173 1.21798 25.8769C2.84188 21.9566 5.95659 18.8419 9.87693 17.218C12.8173 16 16.5449 16 24.0002 16H58.2489C61.13 16 62.5705 16 63.9634 15.7517C65.1998 15.5313 66.4059 15.1661 67.5568 14.6637C68.8535 14.0976 70.0521 13.2986 72.4493 11.7005L83.5507 4.29951C85.9479 2.70141 87.1465 1.90236 88.4432 1.3363C89.5941 0.833863 90.8002 0.468679 92.0366 0.248293C93.4295 0 94.87 0 97.7511 0H272.775C276.935 0 279.016 0 281.005 0.408351C282.77 0.770639 284.479 1.36984 286.083 2.18968C287.892 3.11377 289.517 4.41324 292.765 7.0122L296.988 10.3902C299.587 12.4694 300.886 13.509 302.333 14.2483C303.617 14.9041 304.984 15.3835 306.396 15.6733C307.988 16 309.652 16 312.98 16H346C353.455 16 357.183 16 360.123 17.218C364.043 18.8419 367.158 21.9566 368.782 25.8769C370 28.8173 370 32.5449 370 40.0002H0Z" fill="#E8DDFF"/>
    </svg>
)

export const SelectCity: FC = () => {

    const { city, setCity, desktopMode, fetchResult, saveChoice } = useStorage()
    const go = useNavigate()

    const [loading, setLoading] = useState(false)


    const handleClick = async () => {
        setLoading(true)
        await saveChoice()
        .then((data)=>{
            console.log("Ответ сохранен")
        })
        .catch((err)=>{
            console.error("Не удалось сохранить ответ")
        })

        await fetchResult()
        .then((data)=>{
            setLoading(false)
            ym(`selectCity_${city}`)
            go(-1)
            setTimeout(() => {
                window.scrollTo({top: 1000, behavior: 'smooth'})
            }, 300);
        })
        .catch((err)=>{
            console.error('Не удалось получить результат')
            setLoading(false)
        })
        // go('/preresult')
    }

    const skip = async () => {
        setCity('Другой')
        go('/preresult')
    }

    const MainBlock = useMemo(()=>{
        if (desktopMode) {
            return(
                ({children}: PropsWithChildren) => (
                <BlockGreatDirDesktop
                className="selecting-place__selector__in selecting-place__selector"
                >
                    {children}
                </BlockGreatDirDesktop>
                )
            )
        }

        return (
            ({children}: PropsWithChildren) => (
            <BlockPartsTemplate
            svg={<MainBlockAdditional/>}
            svgPosition='end'
            className='selecting-place__selector__in selecting-place__selector__in--mobile'
            classNameMain="selecting-place__selector"
            >
                {children}
            </BlockPartsTemplate>
            )
        )
    }, [desktopMode])

    
    return(
        <Panel
        oneScreenMode
        popout={loading && <ScreenSpinner />}
        >
            <div
            className='selecting-place'
            >
                <MainBlock>
                    <ScrollSelect 
                    variables={cities}
                    onChange={(n)=>setCity(cities[n])}
                    />
                    {!desktopMode &&
                    <div className='select-area__gradient'/>
                    }
                </MainBlock>
                <BlockPartsTemplate
                classNameMain='selecting-place__button'
                className='selecting-place__button__in'
                svg={<BottomBlockAdditional/>}
                svgPosition="start"
                >
                    <TextWithHeader
                    style={{color: 'black', minHeight: 0, paddingBottom:8}}
                    >
                    Выберите город и узнайте, какие занятия популярны среди местных жителей
                    </TextWithHeader>
                    <Button
                    stretched
                    color='black'
                    onClick={handleClick}
                    >
                        Выбрать город
                    </Button>
                    {/* <Button
                    stretched
                    color='white-light'
                    onClick={skip}
                    >
                        Пропустить
                    </Button> */}
                </BlockPartsTemplate>
            </div>
        </Panel>
    )
}