import { ReactNode } from "react"

type mapdictWithNode = {[key: string]: string | JSX.Element}
type mapdict = {[key: string]: string}


export const EVENT_RULES_URL = 'https://yandex.ru/legal/arenda_plus10/ru/'

export const citizen_names: mapdict = {
    'Москва': 'москвичи',
    'Санкт-Петербург': 'петербуржцы',
    'Екатеринбург': 'екатеринбуржцы',
    'Новосибирск': 'новосибирцы',
    'Ростов-на-Дону': 'ростовчане',
    'Краснодар': 'краснодарцы',
    'Нижний Новгород': 'нижегородцы',
    'Самара': 'самарцы',
    'Омск': 'омичи',
    'Красноярск': 'красноярцы',
    'Казань': 'казанцы',
    'Воронеж': 'воронежцы',
    'Саратов': 'саратовцы',
    'Тюмень': 'тюменцы',
    'Уфа': 'уфимцы',
    'Сочи': 'сочинцы'
}

export const cities_genitive: mapdict = {
    'Москва': 'Москвы',
    'Санкт-Петербург': 'Санкт-Петербурга',
    'Екатеринбург': 'Екатеринбурга',
    'Новосибирск': 'Новосибирска',
    'Ростов-на-Дону': 'Ростова-на-Дону',
    'Краснодар': 'Краснодара',
    'Нижний Новгород': 'Нижнего Новгорода',
    'Самара': 'Самары',
    'Омск': 'Омска',
    'Красноярск': 'Красноярска',
    'Казань': 'Казани',
    'Воронеж': 'Воронежа',
    'Саратов': 'Саратова',
    'Тюмень': 'Тюмени',
    'Уфа': 'Уфы',
    'Сочи': 'Сочи'
}

export const activities_instrumental: mapdict = {
    "Скроллинг видео": "Скроллингом видео",
    "Бёрд-вотчинг": "Бёрдвотчингом",
    "Онлайн-шопинг": "Онлайн-шопингом",
    "Хобби-хорсинг": "Хоббихорсингом",
    "Разобрать балкон": "Разбором балкона",
    "Изучать языки": "Изучением языков",
    "Йо-йо": "Йо-йо",
    "Просмотр улиц в картах": "Просмотром улиц",
    "Карвинг по овощам": "Карвингом по овощам",
    "Пазлы": "Пазлами",
    "Бонсай": "Бонсаем"
}

export const activities_result: mapdict = {
    "Скроллинг видео": "скроллинг видео",
    "Бёрд-вотчинг": "бёрдвотчинг",
    "Онлайн-шопинг": "онлайн-шопинг",
    "Хобби-хорсинг": "хоббихорсинг",
    "Разобрать балкон": "разобрать балкон",
    "Изучать языки": "изучать языки",
    "Йо-йо": "йо-йо",
    "Просмотр улиц в картах": "просмотр улиц",
    "Карвинг по овощам": "карвинг по овощам",
    "Пазлы": "пазлы",
    "Бонсай": "бонсай"
}

export const activities: string[] =  [
    "Скроллинг видео",
    "Бёрд-вотчинг",
    "Онлайн-шопинг",
    "Хобби-хорсинг",
    "Разобрать балкон",
    "Изучать языки",
    "Йо-йо",
    "Просмотр улиц в картах",
    "Карвинг по овощам",
    "Пазлы",
    "Бонсай"
]

// export const quiz_questions = [
//     "Вы делегировали квартирный вопрос и наконец освободилось время на маленькие радости. Чем займёмся?",
//     "На ваш телефон пришло пуш-уведомление: «все товары из вишлиста со скидкой до 50%». Ну разве можно устоять против такого, ведь вы уже неделю ничего не заказывали на маркетплейсах. Закупимся?",
//     "А может быть модный хобби-хорсинг, чтобы наконец-то сказать: «Андрюха, по коням?»",
//     "После такого нужно отдохнуть и собрать три в ряд. Согласны?",
//     "В такую погоду хочется заварить имбирный чай, набрать побольше вкусненького и съесть всё за один вечер за просмотром мукбанг-видосов.",
//     "Вспомним игру детства: вы сидите за партой в школе, а после звонка одноклассники зовут бросать йо-йо на переменке. Вы готовы повторить трюки, за которые вам кинули бы респект в школе?",
//     "Как насчёт того, чтобы прогуляться по улицам в онлайн-картах? Забредём в любую точку мира в поисках чего-нибудь интересного, как раньше.",
//     "Кажется, что после такого можно отдохнуть, занимаясь карвингом по овощам. Согласны?",
//     "Пока вы думаете, чем заняться, бабушка уже вышла из соседней комнаты. Держит путь к вам и зовет собирать пазлы. Кажется, вам не отвертеться!",
//     "Слышали, что сейчас модно выращивать миниатюрные деревья прямо в своей квартире. Может, освоим искусство бонсай и начнём с можжевельника?"
// ];

export const quiz_questions = [
<>Итак, у&nbsp;вас наконец-то появилось время для себя. Чем займётесь: посмотрите видео в&nbsp;соцсетях или отправитесь в&nbsp;парк, чтобы наблюдать за&nbsp;птицами?</>,
<>Вы&nbsp;так долго откладывали покупку нового ковра, что он&nbsp;уже начал сниться вам по&nbsp;ночам. Может, пора найти тот самый идеальный вариант на&nbsp;распродаже? Чем займёмся?</>,
<>Всегда мечтали сесть на&nbsp;лошадь и&nbsp;освоить верховую езду, но&nbsp;никак не&nbsp;соберётесь с&nbsp;мыслями? Тогда начните с&nbsp;модного хоббихорсинга! Итак, чем займёмся?</>,
<>Иногда так хочется взять и&nbsp;сделать что-то полезное. Например, наконец разобрать балкон с&nbsp;накопившимися летними вещами и&nbsp;коробками. Может, сейчас самое время? Кто знает, вдруг найдёте что-то ценное! Чем займёмся?</>,
<>Гордились пятёркой по&nbsp;английскому в&nbsp;школе? Самое время освежить знания или начать учить один из&nbsp;популярных языков. Может быть, китайский? Или испанский? Чем займёмся?</>,
<>Помните, как на&nbsp;переменах бросали йо-йо и&nbsp;соревновались в&nbsp;самых сложных трюках? Лупинг, стринг, хопы... Как насчёт тренировки? Чем займёмся?</>,
<>Где лучшие <s>тусовки</s> пляжи? А&nbsp;где искать самый красивый закат? Узнать всё это проще, чем кажется. Откройте приложение карт и&nbsp;отправляйтесь в&nbsp;виртуальное турне&nbsp;&mdash; соберёте себе вишлист для путешествий.</>,
<>Иногда хочется выйти за&nbsp;пределы базовых блюд и&nbsp;вместо супа с&nbsp;фрикадельками научиться вырезать морковные розы. Чем займёмся?</>,
<>Что может быть лучше, чем потратить свободное время на&nbsp;картину из&nbsp;10&nbsp;000&nbsp;пазлов. Попробуйте бросить себе вызов и&nbsp;не&nbsp;потерять последний уголок! Ну&nbsp;что, чем займёмся?</>,
<>Мечтали найти время для медитаций, но&nbsp;как-то не&nbsp;доходили руки? Бонсай&nbsp;&mdash; это идеальный способ замедлиться, сосредоточиться на&nbsp;процессе и&nbsp;отвлечься от&nbsp;суеты. Возможно, пора попробовать! Чем займёмся?</>,
  ]


// export const quiz_answers: mapdict = {
//     "Скроллинг видео": "Похоже, вы прокрутили ленту до конца и изучили все мемы!",
//     "Бёрд-вотчинг": "Круто! И журавля в небе увидели, и голубя рядом семечками прикормили.",
//     "Онлайн-шопинг": "Кажется, у вас появилась парочка «нууу очень нужных» товаров. Да ладно вам, понимаем мы всё!",
//     "Хобби-хорсинг": "Андрюха, мы хобби-хорсим. Возможно, это надолго. По коням!",
//     "Три в ряд": "Опять эти сверкающие камушки в ряду. Кажется, вас не остановить!",
//     "Мукбанг": "Ого! Так вот оказывается ско-о-олько вкусненького можно съесть, смотря на то, как это делают другие.",
//     "Йо-йо": "Кто-то явно не растерял школьные скиллы! Пару оборотов, и вот уже крутые трюки возвращаются в строй.",
//     "Просмотр улиц в картах": "Сейчас бы попасть на улицу Лиссабона в поисках чего-нибудь интересного, а не вот это всё.",
//     "Карвинг по овощам": "Начали с морковки, закончили тыквой. Вот что бывает, когда море свободного времени.",
//     "Пазлы": "Ух, только бы не потерять уголок на финальном этапе!",
//     "Бонсай": "Ваш бонсай уже позирует на подоконнике, словно звезда соцсетей."
//   }

export const quiz_answers: mapdictWithNode = {
    "Скроллинг видео": <>Всё не&nbsp;зря! Теперь в&nbsp;вашем &laquo;Избранном&raquo; несколько новых рецептов и&nbsp;отличное решение для обустройства рабочего места. Ладно, пару мемов друзьям вы&nbsp;тоже отправили</>,
    "Бёрд-вотчинг": <>Круто! И&nbsp;журавля в&nbsp;небе увидели, и&nbsp;голубя рядом семечками прикормили. Или это всё-таки был не&nbsp;журавль?...</>,
    "Онлайн-шопинг": <>Ковёр мечты со&nbsp;скидкой&nbsp;80%, лампа из&nbsp;вишлиста и&nbsp;пара свечек от&nbsp;модного бренда уже едут к&nbsp;вам. Вы&nbsp;не&nbsp;откладывали дела, вы&nbsp;просто ждали идеального момента для покупок</>,
    "Хобби-хорсинг": <>Сначала хоббихорсинг, а&nbsp;дальше&nbsp;&mdash; кто знает, может, и&nbsp;до&nbsp;скачек дойдём! А&nbsp;пока что&nbsp;&mdash; по&nbsp;коням!</>,
    "Разобрать балкон": <>Кто&nbsp;бы мог подумать, что в&nbsp;этих коробках пряталась целая история! Теперь ваш балкон&nbsp;&mdash; почти лофт, а&nbsp;найденная пара кроссовок готовится к&nbsp;летнему сезону</>,
    "Изучать языки": <>Guten Tag, Buenas tardes, 下午好, Buongiorno, 안녕하세요, ну&nbsp;или просто добрый день. Какой язык вам подходит больше вы&nbsp;пока не&nbsp;решили, но&nbsp;начало точно положено!</>,
    "Йо-йо": <>Слэк и&nbsp;чопстик, конечно, не&nbsp;ваши коронные трюки, но&nbsp;приятная ностальгия стоила запутанного между пальцев йо-йо</>,
    "Просмотр улиц в картах": <>Уютное кафе в&nbsp;Касабланке, огромный книжный в&nbsp;Шанхае, модный магазин в&nbsp;Сеуле и&nbsp;роскошный пляж в&nbsp;Тайланде&nbsp;&mdash; малая часть того, что вы&nbsp;успели посмотреть. Теперь пора составлять маршрут для реального путешествия?</>,
    "Карвинг по овощам": <>Первый раз вырезали морковную розу, а&nbsp;уже так здорово! Кажется, что теперь ваши блюда приобретут уникальные подачи. Гости точно будут в&nbsp;восторге!</>,
    "Пазлы": <>Это была не&nbsp;просто картина из&nbsp;10&nbsp;000&nbsp;пазлов, а&nbsp;целый тренажёр для внимания и&nbsp;терпения. Вы&nbsp;справились!</>,
    "Бонсай": <>Правильно полить, защитить от&nbsp;солнечных лучей, не&nbsp;забыть обрезать лишние ветки... Кажется, вы&nbsp;нашли идеальное занятие, чтобы замедлиться</>
}


// export const result_captions: mapdict = {
//     "Скроллинг видео": "Благодаря Яндекс Аренде вы сэкономите больше 8 часов на рутинных задачах, связанных с арендой. Ого, этого времени хватит на просмотр 960 самых популярных видео с рецептами!",
//     "Бёрд-вотчинг": "Благодаря Яндекс Аренде вы сэкономите больше 8 часов на передачах ключей и показах квартир. Этого времени хватит, чтобы стать экспертом в бёрд-вотчинге. Ну или хотя бы понять, что тот голубь совсем не журавль.",
//     "Онлайн-шопинг": "Благодаря Яндекс Аренде вы сэкономите больше 8 часов —  а это 16 распаковок подряд! Там и идеальный ковёр, и новая косметика, и даже до бытовой техники дойдёт!",
//     "Хобби-хорсинг": "Благодаря Яндекс Аренде у вас освободится больше 8 часов. Это 222 круга на хоббихорсе! А там, кто знает, может, дойдёт и до скачек.",
//     "Разобрать балкон": "Благодаря Яндекс Аренде вы сэкономите больше 8 часов. Этого времени хватит, чтобы разобрать все коробки на балконе и превратить его в уютный лофт. А найденная пара кроссовок? Вот это удача!",
//     "Изучать языки": "Благодаря Яндекс Аренде вы сэкономите больше 8 часов. Это идеальный шанс вспомнить школьные знания английского и выучить парочку новых слов на испанском и китайском. Кто знает, может, ваш следующий отпуск будет в Сеуле?",
//     "Йо-йо": "Ого! Благодаря Яндекс Аренде у вас освободится больше 8 часов. За это время можно отточить легендарный «Слэк» или даже освоить «Чопстикс». Кто знает, может, новый чемпионский трюк будет носить ваше имя!",
//     "Просмотр улиц в картах": "С Яндекс Арендой вы сэкономите более 8 часов. Эти часы можно посвятить прогулке по модным районам Сеула или пляжам Таиланда. Кто знает, может, вдохновитесь и начнёте собирать чемодан?",
//     "Карвинг по овощам": "Благодаря Яндекс Аренде вы сэкономите больше 8 часов на передаче ключей, составлении договоров и показах квартир. За это время можно вырезать целый букет овощных роз. Этого хватит на 2 завтрака, 2 обеда и 3 ужина!",
//     "Пазлы": "Благодаря Яндекс Аренде у вас освободится больше 8 часов. Этого хватит, чтобы собрать огромную картину из 15 000 пазлов. Главное — не потерять последний уголок под диваном!",
//     "Бонсай": "Благодаря Яндекс Аренде вы сэкономите больше 8 часов на рутинных задачах, связанных с арендой. Этого времени хватит, чтобы ментально преисполниться и вырастить целый мини-лес из бонсай, став его личным парикмахером-стилистом!"
// }

export const result_captions: mapdict = {
  "Скроллинг видео": "С Яндекс Арендой появляется свободное время. Передайте задачи по аренде сервису и наслаждайтесь тем, что вам нравится. Например, скроллингом видео",
  "Бёрд-вотчинг": "С Яндекс Арендой появляется свободное время. Передайте задачи по аренде сервису и наслаждайтесь тем, что вам нравится. Например, бёрдвотчингом",
  "Онлайн-шопинг": "С Яндекс Арендой появляется свободное время. Передайте задачи по аренде сервису и наслаждайтесь тем, что вам нравится. Например, онлайн-шопингом",
  "Хобби-хорсинг": "С Яндекс Арендой появляется свободное время. Передайте задачи по аренде сервису и наслаждайтесь тем, что вам нравится. Например, хоббихорсингом",
  "Разобрать балкон": "С Яндекс Арендой появляется свободное время. Передайте задачи по аренде сервису и наслаждайтесь тем, что вам нравится. Например, разбором балкона от накопившихся вещей",
  "Изучать языки": "С Яндекс Арендой появляется свободное время. Передайте задачи по аренде сервису и наслаждайтесь тем, что вам нравится. Например, изучением языков",
  "Йо-йо": "С Яндекс Арендой появляется свободное время. Передайте задачи по аренде сервису и наслаждайтесь тем, что вам нравится. Например, бросанием йо-йо",
  "Просмотр улиц в картах": "С Яндекс Арендой появляется свободное время. Передайте задачи по аренде сервису и наслаждайтесь тем, что вам нравится. Например, просмотром улиц в картах",
  "Карвинг по овощам": "С Яндекс Арендой появляется свободное время. Передайте задачи по аренде сервису и наслаждайтесь тем, что вам нравится. Например, карвингом по овощам",
  "Пазлы": "С Яндекс Арендой появляется свободное время. Передайте задачи по аренде сервису и наслаждайтесь тем, что вам нравится. Например, сбором пазлов",
  "Бонсай": "С Яндекс Арендой появляется свободное время. Передайте задачи по аренде сервису и наслаждайтесь тем, что вам нравится. Например, искусством бонсай"
}

// export const result_captions: mapdict = {
//     "Скроллинг видео": "Благодаря Яндекс Аренде вы сэкономили больше 8 часов на рутинных задачах, связанных с арендой. Этого времени хватит на просмотр 960 коротких видео с котиками!",
//     "Бёрд-вотчинг": "Благодаря Яндекс Аренде вы сэкономили больше 8 часов на передаче ключей, составлении договоров и показах квартир. Это время можно провести с пользой и найти больше 6 редких птиц! Или хотя бы попытаться не уснуть, пока ждёте, что они покажутся.",
//     "Онлайн-шопинг": "Благодаря Яндекс Аренде вы сэкономили больше 8 часов — это 16 распаковок подряд! Кто знает, может, начнёте карьеру шопоголика-блогера?",
//     "Хобби-хорсинг": "Благодаря Яндекс Аренде у вас освободилось больше 8 часов. Это 222 круга на хобби-хорсинге. Почти как до ближайшего гипермаркета и обратно!",
//     "Три в ряд": "Благодаря Яндекс Аренде вы сэкономили больше 8 часов на рутинных задачах, связанных с арендой. За это время можно пройти 10 уровней в «Три в ряд» и наконец-то доказать маме, что это тоже талант!",
//     "Мукбанг": "Благодаря Яндекс Аренде вы сэкономили больше 8 часов. За это время можно случайно опустошить весь холодильник. Оставьте чуть-чуть, хотя бы на завтра!",
//     "Йо-йо": "Ого! Благодаря Яндекс Аренде у вас освободилось больше 8 часов. За это время можно крутить йо-йо без остановки. Главное, не улететь в параллельную вселенную от таких скоростей!",
//     "Просмотр улиц в картах": "С Яндекс Арендой вы сэкономили более 8 часов. Это шанс прогуляться по 15 городам мира, не вставая с дивана. Кто знает, вдруг встретите известного футболиста, прогуливаясь по улицам Мадрида?",
//     "Карвинг по овощам": "Благодаря Яндекс Аренде вы сэкономили больше 8 часов на передаче ключей, составлении договоров и показах квартир. За это время можно вырезать целый автопортрет из тыквы! Ну или хотя бы забавного огуречного человечка, который станет звездой вашего дома.",
//     "Пазлы": "Благодаря Яндекс Аренде у вас освободилось больше 8 часов. Времени как раз хватает, чтобы собрать 5 больших картин из пазлов. Ну или одну, если все детальки упорно прячутся под диваном…",
//     "Бонсай": "Благодаря Яндекс Аренде вы сэкономили больше 8 часов на рутинных задачах, связанных с арендой. Этого времени хватит, чтобы вырастить целый мини-лес из бонсай и стать его личным парикмахером-стилистом!"
// }

export const result_titles: mapdictWithNode = quiz_answers