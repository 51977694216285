import { BlockProps } from "../types";

export const BlockRightBottomCap = ({
    children,
    ...rest
}: BlockProps): JSX.Element => {
    return(
        <>
        <svg className="clippy-base">
        <defs>
            <clipPath id="clip-block_right_bottom_cap" clipPathUnits="objectBoundingBox">
            <path d="M0,0.107 C0,0.069,0,0.051,0.005,0.036 C0.009,0.024,0.015,0.014,0.024,0.007 C0.033,0,0.045,0,0.069,0 H0.931 C0.955,0,0.967,0,0.976,0.007 C0.985,0.014,0.991,0.024,0.995,0.036 C1,0.051,1,0.069,1,0.107 V0.893 C1,0.931,1,0.949,0.995,0.964 C0.991,0.976,0.985,0.986,0.976,0.993 C0.967,1,0.955,1,0.931,1 H0.688 C0.678,1,0.672,1,0.667,0.998 C0.663,0.997,0.659,0.994,0.655,0.99 C0.65,0.986,0.647,0.98,0.639,0.969 L0.615,0.931 C0.607,0.92,0.604,0.914,0.599,0.91 C0.595,0.906,0.591,0.903,0.587,0.902 C0.582,0.9,0.577,0.9,0.566,0.9 H0.069 C0.045,0.9,0.033,0.9,0.024,0.893 C0.015,0.886,0.009,0.876,0.005,0.864 C0,0.849,0,0.831,0,0.793 V0.107" fill="#F8D849"/>
            </clipPath>
        </defs>
        </svg>
        <div
        {...rest}
        style={{clipPath:"url(#clip-block_right_bottom_cap)", ...rest.style}}
        >
            {children}
        </div>
        </>
    )
}