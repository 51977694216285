import { FC, useRef, useState, Fragment, useEffect } from "react";
import { BlockBigScreenPart, BlockSmallCenterCap, Button, Panel, ScrollSelect } from "../../components";
import './PreResultsLoading.css';
import { useStorage } from "../../hooks";
import { useNavigate, useNavigation } from "react-router-dom";
import { activities_button_images } from "../../images/quiz_buttons/images";
import { activities_images } from "../../images/activities";
import { ym } from "../../ym";

const InnerContent = (): JSX.Element => {
    return(
        <svg className="preresult-place__selector__innercontent" width="390" height="546" viewBox="0 0 390 546" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M200 256.248C213.997 237.066 225.444 217.012 236.521 195.966C246.225 177.528 257.359 158.569 262.372 138.159C267.877 115.745 265.487 92.7708 260.282 70.5624C256.061 52.5525 249.811 34.5511 242.791 17.431C237.991 5.72405 -46.589 -922.237 -51 -934" stroke="#7D24FF" stroke-width="8" stroke-linecap="round"/>
        <path d="M227.385 275.74C244.566 261.683 260.009 243.571 279.512 232.724C308.557 216.569 296.346 269.683 299.974 283.208C302.346 292.046 308.756 291.191 315.359 286.195C343.111 265.193 360.015 227.167 371.817 196.055C390.223 147.538 653.903 -498.03 662.5 -549" stroke="#7D24FF" stroke-width="8" stroke-linecap="round"/>
        <path d="M186.269 310.455C188.66 304.989 187.192 297.533 185.915 292.107C177.578 256.675 156.71 224.198 131.612 198.292C118.543 184.803 99.1374 166.562 79.1485 164.533C55.7118 162.153 45.2963 196.71 42.7842 214.371C40.4996 230.433 47.9049 242.945 64.3041 246.356C96.332 253.019 104.382 218.494 98.9445 194.092C84.2912 128.335 -77.1387 -38.3739 -135.5 -65" stroke="#7D24FF" stroke-width="8" stroke-linecap="round"/>
        <path d="M230.958 328.346C246.137 344.845 261.447 361.097 279.211 374.901C305.486 395.318 333.677 406.085 366.566 409.947C367.51 410.058 386.075 412.226 386 412C385.678 411.035 1265.16 467.47 1264 466.5" stroke="#7D24FF" stroke-width="8" stroke-linecap="round"/>
        <path d="M196.792 287.129C166.372 287.129 93.0694 288.888 100.907 336.318C110.488 394.303 185.557 445.002 145.109 506.441C129.967 529.442 105.077 536.231 80.7324 522.706C62.9999 512.854 53.008 482.291 45.4838 461.559C40.8228 448.716 35.9237 435.771 28.5961 424.157C26.233 420.411 23.4099 416.141 18.8489 414.976C13.4563 413.599 -1476.01 539.778 -1481 541.5" stroke="#7D24FF" stroke-width="8" stroke-linecap="round"/>
        </svg>

    )
}

export const PreResultsLoading: FC = () => {

    const go = useNavigate()
    const { fetchResult, saveChoice, currentChoice } = useStorage()
    const [load, setLoad] = useState<[boolean, boolean]>([false, false])

    const [randomIndex, setRandomIndex] = useState(0)

    useEffect(()=>{
        setTimeout(() => {
            ym('showResultScreen')
            go('/result')
        }, 2800);

        // async function fetchData() {
        //     await saveChoice()
        //     .catch((err)=>{
        //         console.log('Ответ не был отправлен')
        //     })
        //     await fetchResult()
        //     .then((data)=>{
        //         setLoad(prev=>[true, prev[1]])
        //     })
        // }
        // fetchData()
    }, [])

    // useEffect(()=>{
    //     if (load[0] && load[1]){
    //         go('/result')
    //     }
    // }, [load])

    useEffect(()=>{
        const interval = setInterval(() => {
            setRandomIndex(Math.floor(Object.keys(activities_button_images).length*Math.random()))
        }, 300);

        return () => clearInterval(interval)
    }, [])

    return(
        <Panel
        oneScreenMode
        style={{paddingTop:44}}
        >
            <div
            className='preresult-place'
            >
                <BlockBigScreenPart
                className='preresult-place__selector'
                >
                    <InnerContent />
                    <div className='center-sticker'>
                        <img src={Object.values(activities_images)[randomIndex]} />
                    </div>
                </BlockBigScreenPart>
                <BlockSmallCenterCap
                className='preresult-place__text'
                >
                    Вычисляем...
                </BlockSmallCenterCap>
            </div>
        </Panel>
    )
}