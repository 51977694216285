import { ButtonHTMLAttributes, HTMLProps, ReactNode } from "react";
import { classNames } from "../../utils";
import './Button.css';


interface ButtonProps extends HTMLProps<HTMLAnchorElement> {
    children: ReactNode;
    stretched?: boolean;
    color?: "purple" | "white" | "purple-light" | "white-light" | 'black';
}

export const Button = ({
    children,
    stretched = false,
    color = 'purple',
    ...rest
}: ButtonProps): JSX.Element => {
    return(
        <a
        {...rest}
        className={
            classNames(
                'button', 
                `button--${color}-color`,
                stretched && 'button--stretched',
                rest.className
            )
        }
        >
            <span
            className={
                classNames('button--innervalue')
            }
            >
                {children}
            </span>
        </a>
    )
}