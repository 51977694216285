import { FC, HTMLProps, ReactNode } from "react";
import { BlockCenterBottomResultCap, BlockPartsTemplate, BlockSmallLeftRightBottomCaps, Button, IconCircle, IconCircleRow, Link, Panel, TextWithHeader } from "../../components";
import { useStorage } from "../../hooks";
import './Results.css';
import { activities, activities_instrumental, activities_result, cities_genitive, citizen_names, EVENT_RULES_URL, quiz_answers, result_captions, result_titles } from "../../consts";
import { OKIcon, TGIcon, VKIcon, YandexRentHorizontal } from "../../icons";
import { activities_images } from "../../images/activities";
import { activities_button_images } from "../../images/quiz_buttons/images";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import {Helmet} from 'react-helmet-async';
import { ym } from "../../ym";

interface ResultCardProps extends HTMLProps<HTMLDivElement> {
    top?: string | number;
    bottom?: string | number;
    sticker?: ReactNode;
}

interface AdviceCardProps extends HTMLProps<HTMLDivElement> {
    top?: string | number;
    caption?: string | number;
}

const ResultCard = ({
    top,
    bottom,
    sticker
}: ResultCardProps): JSX.Element => {
    return(
        <div
        className='result-card'
        >
            <div
            className="result-card__values-column"
            >
                <span className="result-card__values__top bold-font">
                    {top}
                </span>
                <div className='result-card__gap' />
                <span className="result-card__values__bottom">
                    {bottom}
                </span>
            </div>
            {sticker &&
            <div
            className='result-card__sticker'
            >
                {sticker}
            </div>
            }
        </div>
    )
}

const AdviceCard = ({
    top,
    caption
}: AdviceCardProps): JSX.Element => {
    return(
        <div
        className="advice-card"
        >
            <span className="advice-card__top bold-font">
                {top}
            </span>
            <div className="advice-card__caption">
                {caption}
            </div>
        </div>
    )
}

const CardsHandler = ({children, gap}: {children: ReactNode, gap?: number | string}): JSX.Element => {
    return(
        <div
        className='cards-handler'
        style={gap ? {gap} : undefined}
        >
            {children}
        </div>
    )
}

const MainBlockAdditional = () => (
    <svg width="370" height="40" viewBox="0 0 370 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0H370C370 8.83656 362.837 16 354 16H313.924C309.896 16 307.882 16 305.993 16.4662C304.319 16.8795 302.722 17.5608 301.266 18.4838C299.622 19.5249 298.228 20.979 295.441 23.8872L291.338 28.1692C287.157 32.5315 285.067 34.7127 282.602 36.2744C280.416 37.6587 278.022 38.6807 275.51 39.3006C272.677 40 269.656 40 263.614 40H108.386C102.344 40 99.3233 40 96.49 39.3006C93.9785 38.6807 91.5836 37.6587 89.3982 36.2744C86.933 34.7127 84.8427 32.5315 80.6621 28.1692L76.5586 23.8872C73.7715 20.979 72.378 19.5249 70.7345 18.4838C69.2776 17.5608 67.681 16.8795 66.0066 16.4662C64.1178 16 62.1038 16 58.0757 16H16C7.16344 16 0 8.83656 0 0Z" fill="#E8DDFF"/>
    </svg>
)

const SelectCityBlockAdditional = () => (
    <svg width="370" height="40" viewBox="0 0 370 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 40C0 32.5449 0 28.8174 1.21793 25.8771C2.84183 21.9566 5.95662 18.8418 9.87706 17.2179C12.8174 16 16.5449 16 24 16H58.2489C61.13 16 62.5705 16 63.9634 15.7517C65.1998 15.5313 66.4059 15.1661 67.5568 14.6637C68.8535 14.0976 70.0521 13.2986 72.4493 11.7005L83.5507 4.29951C85.9479 2.70141 87.1465 1.90236 88.4432 1.3363C89.5941 0.833863 90.8002 0.468679 92.0366 0.248293C93.4295 0 94.87 0 97.7511 0H272.775C276.935 0 279.016 0 281.005 0.408351C282.77 0.770639 284.479 1.36984 286.083 2.18968C287.892 3.11377 289.517 4.41324 292.765 7.0122L296.988 10.3902C299.587 12.4694 300.886 13.509 302.333 14.2483C303.617 14.9041 304.984 15.3835 306.396 15.6733C307.988 16 309.652 16 312.98 16H346C353.455 16 357.183 16 360.123 17.2179C364.043 18.8418 367.158 21.9566 368.782 25.8771C370 28.8174 370 32.5449 370 40H0Z" fill="#FFE671"/>
    </svg>
)

const MainBottomBlockAdditional = () => (
    <svg width="370" height="40" viewBox="0 0 370 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 25.6C0 16.6392 0 12.1587 1.7439 8.73615C3.27787 5.72556 5.72556 3.27787 8.73615 1.7439C12.1587 0 16.6392 0 25.6 0H53.3961C57.3096 0 59.2663 0 61.1077 0.442081C62.7403 0.834029 64.301 1.4805 65.7326 2.35776C67.3472 3.34723 68.7308 4.73084 71.4981 7.49807L73.0039 9.00386C78.5383 14.5383 81.3055 17.3055 84.5349 19.2845C87.398 21.039 90.5194 22.3319 93.7846 23.1158C97.4674 24 101.381 24 109.208 24H265.173C270.89 24 273.749 24 276.447 23.5352C282.101 22.5606 287.39 20.0835 291.759 16.3632C293.843 14.5886 295.673 12.3924 299.333 8C301.164 5.80379 302.079 4.70568 303.121 3.81838C305.305 1.95824 307.949 0.71968 310.777 0.232425C312.125 0 313.555 0 316.414 0H344.4C353.361 0 357.841 0 361.264 1.7439C364.274 3.27787 366.722 5.72556 368.256 8.73615C370 12.1587 370 16.6392 370 25.6V39.9998H0V25.6Z" fill="#E8DDFF"/>
    </svg>
)


export const Results: FC = () => {

    const { result, city, currentChoice, desktopMode, userMode } = useStorage()

    const city_makeform = cities_genitive[city as string]
    const citizen_name = citizen_names[city as string]

    const [params, _] = useSearchParams()
    const res = params.get('res')

    const go = useNavigate()

    const toPromocode = () => go('/promocode')

    const toSelectCity = () => go('/select_city')

    const ymToArendaSite = () => {
        ym('toArendaSiteFromResult')
    }

    const ymShare = (tag: string) => {
        ym(`toShareFromResult_${tag}`)
    }  

    // const shareLink = `https://kogdaestvremya.ru/?r=${currentChoice ? activities.indexOf(currentChoice)+1 : 1}`
    const shareLink = `https://kogdaestvremya.ru/`


    return (
        <Panel
        className="result-panel"
        >
            <div
            className='result-banner'
            // style={userMode === '0' ? {height: '69.5dvh'} : undefined}
            >
                <a
                className='result-banner__main-part__logo'
                style={{padding: '18px 0'}}
                href={
                    userMode == '1' ? 
                    'https://arenda.yandex.ru/?utm_source=special&utm_medium=landing&utm_campaign=your-time' :
                    'https://arenda.yandex.ru/arendatoru/?utm_source=special&utm_medium=landing&utm_campaign=your-time'
                }
                target="_blank"
                >
                    <YandexRentHorizontal />
                </a>
                <BlockPartsTemplate
                svgPosition="end"
                svg={!desktopMode ? <MainBlockAdditional /> : <></>}
                classNameMain="result-banner__main-part__out"
                className="result-banner__main-part__in"
                style={desktopMode ? {borderRadius: 13, marginBottom: 24} : undefined}
                >
                    <div
                    className='result-banner__stickers-container__sticker sticker--big-right'
                    >
                        <img 
                        src={activities_images[currentChoice as string]}
                        />
                    </div>
                    <span className="result-banner__main-part__title__action bold-font">
                        <span className='result-action-box'>
                            {activities_result[currentChoice as string]}
                        </span>
                    </span>
                    <div className="result-banner__main-part__title bold-font">
                        {result_titles[currentChoice as string]}
                    </div>
                    <div
                    className="result-banner__main-part__bottom"
                    >
                        {result_captions[currentChoice as string]}
                        {desktopMode &&
                        <Button
                        color='purple'
                        className="arenda-button button--slim"
                        onClick={ymToArendaSite}
                        href={
                            userMode == '1' ? 
                            'https://arenda.yandex.ru/?utm_source=special&utm_medium=landing&utm_campaign=your-time' :
                            'https://arenda.yandex.ru/arendatoru/?utm_source=special&utm_medium=landing&utm_campaign=your-time'
                        }
                        target="_blank"
                        >
                            Перейти в Яндекс Аренду
                        </Button>
                        }
                    </div>
                </BlockPartsTemplate>
                <BlockPartsTemplate
                svgPosition="start"
                svg={<MainBottomBlockAdditional/>} 
                className='result-banner__bottom-part__in'
                classNameMain='result-banner__bottom-part__out'
                >
                    <span>
                        {
                            userMode == '1' ?
                            'Поделитесь результатом и получите дополнительные 10% к первой арендной плате после сдачи квартиры с Яндекс Арендой' :
                            'Поделитесь результатом в социальных сетях'
                        }
                    </span>
                    {userMode === '1' &&
                    <Link 
                    style={{paddingTop:8}}
                    target="_blank"
                    href={EVENT_RULES_URL}
                    >
                        Правила акции
                    </Link>
                    }
                </BlockPartsTemplate>
                <IconCircleRow 
                    className='result-banner__bottom-part__socials'
                    style={{color:'var(--app-color-purple)'}}
                    icons={[
                        <IconCircle
                         icon={<VKIcon/>} 
                         target="_blank"
                         href={`https://vk.com/share.php?url=${shareLink}`}
                         onClick={()=>{
                            ymShare('VK')
                            userMode === '1' && toPromocode()
                         }}
                         />,
                        <IconCircle 
                        target="_blank"
                        href={`https://t.me/share/url?url=${shareLink}`}
                        icon={<TGIcon/>} 
                        onClick={()=>{
                            ymShare('TG')
                            userMode === '1' && toPromocode()
                         }}
                        />,
                        <IconCircle 
                        target="_blank"
                        href={`https://connect.ok.ru/offer?url=${shareLink}`}
                        icon={<OKIcon/>} 
                        onClick={()=>{
                            ymShare('OK')
                            userMode === '1' && toPromocode()
                         }}
                        />
                    ]}
                />
            </div>
            {!city &&
            <>
            <div 
            style={{height: 80, width: 1}}
            />
            <BlockPartsTemplate
            svgPosition="start"
            svg={<SelectCityBlockAdditional/>}
            className='block-select-city__in'
            classNameMain='block-select-city__out'
            >
                <span
                className="block-select-city__text"
                >
                    Выберите город и узнайте, какие занятия популярны среди местных жителей
                </span>
                <Button
                stretched
                color='black'
                onClick={toSelectCity}
                >
                    Выбрать город
                </Button>
            </BlockPartsTemplate>
            </>
            }
            {(city && result) &&
            <>
            <div
            style={{marginTop: 80, marginBottom: 16}}
            className='text-block text-block--purple'
            >
                {city != 'Другой' ? `Вместе с вами ${currentChoice?.toLowerCase()} выбрало ${result?.result_in_city}% жителей ${city_makeform}` : `Вместе с вами ${currentChoice?.toLowerCase()} выбрало ${result?.result_in_city}% пользователей`}
            </div>
            <CardsHandler>
                <div
                className='text-block'
                style={{padding:0}}
                >
                    {city != 'Другой' ? `Кстати, если ${citizen_name} выберут быть счастливыми, ещё они займутся:` : `Вот, что выбрали другие пользователи:`}
                </div>
                {result?.results_stat.map(([value, percentage], i)=>{
                    const value_ = activities_instrumental[value as string];
                    const percentage_ = `${percentage}%`;
                    // const inverted_values = !((i+1)%2 == 0);

                    return(
                        <ResultCard 
                        bottom={value_}
                        top={percentage_}
                        sticker={
                            <img 
                            src={activities_images[value]}
                            />
                        }
                        />
                    )
                    })}
            </CardsHandler>
            </>
            }
            {!desktopMode && 
            <>
            <div style={{height: 70, width: 1}} />
            <div
            className='down-fixed'
            >
                <Button
                stretched
                color='purple'
                onClick={ymToArendaSite}
                href={
                    userMode == '1' ? 
                    'https://arenda.yandex.ru/?utm_source=special&utm_medium=landing&utm_campaign=your-time' :
                    'https://arenda.yandex.ru/arendatoru/?utm_source=special&utm_medium=landing&utm_campaign=your-time'
                }
                target="_blank"
                >
                    Перейти в Яндекс Аренду
                </Button>
            </div>
            </>
            }
        </Panel>
    )
}