import { AnchorHTMLAttributes, DetailedHTMLProps, PropsWithChildren, ReactNode } from "react"
import { classNames } from "../../utils"
import './Link.css';


interface LinkProps extends DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
    children?: ReactNode;
}

export const Link = ({
    children,
    ...rest
}: LinkProps) => {
    return(
        <a
        {...rest}
        className={
            classNames(
                'link-text',
                rest.className
            )
        }
        >
            {children}
        </a>
    )
}