import { FC, useState } from "react"
import './Promocode.css';
import { BlockBigLeftTopLongCap, BlockCenterTopCap, Button, Link, PaddingDiv, Panel, StretchedBox, TextWithHeader } from "../../components";
import { YandexRentHorizontal, YandexRentHorizontalBlack } from "../../icons";
import { BigIconHappySociety } from "../../icons/BigIconHappySociety/BigIconHappySociety";
import { useNavigate, useParams } from "react-router-dom";
import { useStorage } from "../../hooks";
import { ContentPromocodeSVG } from "./ContentSVG";
import { EVENT_RULES_URL } from "../../consts";
import { ym } from "../../ym";

export const Promocode: FC = () => {

    const {desktopMode} = useStorage()
    const [copied, setCopied] = useState<boolean>(false)

    const go = useNavigate()

    const copyPromocode = () => {
        navigator.clipboard.writeText('вашевремя')
        .then((data)=>{
            console.log(data)
            setCopied(true)
            ym('copyPromo')
        })
        .catch((err)=>{
            console.log(err)
        })
    }

    return(
        <Panel
        className='promocode-panel'
        oneScreenMode={!desktopMode}
        // oneScreenMode
        >
            <div
            className='promocode-screen'
            >
                <div
                className="promocode-screen__background"
                >
                    <svg className="clippy-base">
                        <defs>
                            <clipPath id="clip-block_great_dir_desktop" clipPathUnits="objectBoundingBox">
                            <path d="M0,0.139 C0,0.109,0,0.094,0.006,0.082 C0.011,0.072,0.019,0.064,0.029,0.059 C0.041,0.053,0.055,0.053,0.085,0.053 H0.423 C0.436,0.053,0.443,0.053,0.449,0.052 C0.454,0.051,0.459,0.048,0.464,0.045 C0.469,0.042,0.474,0.038,0.483,0.028 L0.487,0.025 C0.496,0.016,0.501,0.011,0.506,0.008 C0.511,0.005,0.516,0.003,0.521,0.001 C0.527,0,0.534,0,0.547,0 H0.915 C0.945,0,0.959,0,0.971,0.006 C0.981,0.011,0.989,0.019,0.994,0.029 C1,0.041,1,0.055,1,0.085 V0.915 C1,0.945,1,0.959,0.994,0.971 C0.989,0.981,0.981,0.989,0.971,0.994 C0.959,1,0.945,1,0.915,1 H0.085 C0.055,1,0.041,1,0.029,0.994 C0.019,0.989,0.011,0.981,0.006,0.971 C0,0.959,0,0.945,0,0.915 V0.139" fill="#F8D849"/>
                            </clipPath>
                        </defs>
                    </svg>
                    <div
                    className="promocode-screen__background__biglogo"
                    >
                        <ContentPromocodeSVG />
                    </div>
                </div>
                <div
                className="promocode-screen__bottom"
                >
                    <BlockCenterTopCap
                    style={{width: '100%', backgroundColor: desktopMode ? 'var(--app-color-card-purple)' : "white"}}
                    >
                    <PaddingDiv
                    horizontal={8}
                    top={20}
                    style={{width:'100%', height:'100%', boxSizing:'border-box'}}
                    >
                        <StretchedBox 
                        gap={8}
                        style={{
                            paddingBottom: 8
                        }}
                        >
                            <div
                            style={{flexGrow:1, display: 'flex', flexDirection: 'row', justifyContent: 'center'}}
                            >
                                <div
                                style={{width: '85%', display:'flex', flexDirection:'column', alignItems:'center', padding: '4px 0'}}
                                >

                                <TextWithHeader
                                textAlign="center"
                                style={{width:'100%', paddingTop: 16, paddingBottom: 16}}
                                >
                                    Введите промокод в специальное поле при подаче заявки и получите дополнительные 10% от размера первой арендной платы<br/>
                                </TextWithHeader>
                                <Link 
                                    style={{ textAlign: 'center'}}
                                    target="_blank"
                                    href={EVENT_RULES_URL}
                                    >
                                        Правила акции
                                    </Link>
                                </div>
                            </div>
                            <Button 
                            stretched 
                            color='purple'
                            onClick={copyPromocode}
                            >{copied ? 'Промокод скопирован!' : 'Скопировать промокод'}</Button>
                            <Button 
                            stretched 
                            color='purple-light'
                            onClick={()=>{
                                go(-1)
                                ym('backFromPromoToResult')
                            }}
                            >К результатам</Button>
                        </StretchedBox>
                    </PaddingDiv>
                </BlockCenterTopCap>
                </div>
            </div>
        </Panel>
    )
}