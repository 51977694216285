import { FC, forwardRef, HTMLProps, PropsWithChildren, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { BlockPartsTemplate, Button, Panel, PartedProgress, TextWithHeader } from "../../components";
import './Quiz.css';
import { classNames } from "../../utils";
import { activities, quiz_answers, quiz_questions } from "../../consts";
import { images } from "../../images/quiz_backgrounds/images";
import { useNavigate } from "react-router-dom";
import { activities_button_images } from "../../images/quiz_buttons/images";
import { useStorage } from "../../hooks";
import { ym } from "../../ym";


const cards_colors = ['#F1EFED', '#F1EFED', '#F1EFED']


interface QuizCardProps extends HTMLProps<HTMLDivElement> {
    src?: string;
}

const QuizCard = forwardRef<HTMLDivElement, QuizCardProps>(({
    src,
    ...rest
}, ref): JSX.Element => {

    return(
        <div
        {...rest}
        className={classNames(
            'quiz-card',
            rest.className
        )}
        ref={ref}
        >
            {src &&
            <img
            className="quiz-card__cover"
            src={src}
            />
            }
        </div>
    )
})

const BlockAnswerAdditional = () => (
    <svg width="370" height="40" viewBox="0 0 370 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 40C0 32.5449 0 28.8174 1.21793 25.8771C2.84183 21.9566 5.95662 18.8418 9.87706 17.2179C12.8174 16 16.5449 16 24 16H58.2489C61.13 16 62.5705 16 63.9634 15.7517C65.1998 15.5313 66.4059 15.1661 67.5568 14.6637C68.8535 14.0976 70.0521 13.2986 72.4493 11.7005L83.5507 4.29951C85.9479 2.70141 87.1465 1.90236 88.4432 1.3363C89.5941 0.833863 90.8002 0.468679 92.0366 0.248293C93.4295 0 94.87 0 97.7511 0H272.775C276.935 0 279.016 0 281.005 0.408351C282.77 0.770639 284.479 1.36984 286.083 2.18968C287.892 3.11377 289.517 4.41324 292.765 7.0122L296.988 10.3902C299.587 12.4694 300.886 13.509 302.333 14.2483C303.617 14.9041 304.984 15.3835 306.396 15.6733C307.988 16 309.652 16 312.98 16H346C353.455 16 357.183 16 360.123 17.2179C364.043 18.8418 367.158 21.9566 368.782 25.8771C370 28.8174 370 32.5449 370 40H0Z" fill="#E8ECFD"/>
    </svg>
)

interface AlertProps {
    header?: string;
    text?: ReactNode;
    buttonValue?: string;
    onButtonClick?: () => void;
}

const Alert = ({
    header,
    text,
    buttonValue,
    onButtonClick
}: AlertProps) => {
    return(
        <div
        className='alert-popout'
        style={{
            maxWidth: '70%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: 16,
            borderRadius: 20,
            padding: '20px',
            backgroundColor: 'var(--app-color-card-blue)'
        }}
        >
            <TextWithHeader
            textAlign='left'
            style={{minHeight: 0, gap: 20}}
            header={header}
            >
                {text}
            </TextWithHeader>
            <Button
            stretched
            color='purple'
            onClick={onButtonClick}
            >{buttonValue}</Button>
        </div>
    )
}

export const Quiz: FC = () => {

    const [step, setStep] = useState<number>(0)
    const activeCardRef = useRef<HTMLDivElement>(null)
    const [inProgress, setInProgress] = useState<boolean>(false)
    const [currentChoice, setCurrentChoice] = useState<{value: string, pos: 0 | 1} | null>(null)

    const [popout, setPopout] = useState<ReactNode | null>(null)

    const {setCurrentChoice: setCurrentGlobalChoice, desktopMode, userMode} = useStorage()

    const go = useNavigate()

    const answer = (value: string, pos: 0 | 1) => {
        ym(`prssAnswer_Q${step+1}`)
        setInProgress(true)
        if (!activeCardRef.current) return ;

        activeCardRef.current?.classList.add('quiz-card--inmove')

        setTimeout(() => {
            if (activeCardRef.current){
                activeCardRef.current.style.zIndex = `-${step+1}`
            }
        }, 550);

        setTimeout(() => {
            activeCardRef.current?.classList.remove('quiz-card--inmove')
            setStep(prev=>prev+1)
            setCurrentChoice({value, pos})
            setInProgress(false)
        }, 1100);
    }
 
    useEffect(()=>{
        // if (step == 10) {
        //     setCurrentGlobalChoice(currentChoice?.value!)
        //     go('/preresult')
        // }

        if (step == 2) {
            if (userMode == '1'){
                setPopout(
                    <Alert 
                    text='Просто напомним, что идеальных жильцов можно найти на Яндекс Аренде. И не нужно ждать распродаж! '
                    buttonValue="Далее"
                    onButtonClick={()=>setPopout(null)}
                    />
                )
            }else{
                setPopout(
                    <Alert 
                    text='Просто напомним, что идеальную квартиру можно найти на Яндекс Аренде. И не нужно ждать распродаж!'
                    buttonValue="Далее"
                    onButtonClick={()=>setPopout(null)}
                    />
                )
            }
        }

        if (step == 7) {
            setPopout(
                <Alert 
                text='Интересный факт: выездные менеджеры Яндекс Аренды каждый день чувствуют себя участниками виртуального путешествия, ведь они уже точно побывали во всех районах города'
                buttonValue="Далее"
                onButtonClick={()=>setPopout(null)}
                />
            )
        }
    }, [step])

    const toEnd = (result: string) => {
        setPopout(
            <Alert 
            text='Бонсай — это про терпение и сосредоточенность. Менеджеры Яндекс Аренды, кажется, собрали бонсаевый сад, иначе как ещё объяснить их спокойствие и готовность помочь'
            buttonValue="Далее"
            onButtonClick={()=>{
                setPopout(null)
                setCurrentGlobalChoice(result)
                go('/preresult')
            }}
            />
        )
        ym('prssAnswer_Q10')
    }

    const firstChoice = !currentChoice ? 
        activities[step] : 
        (
            currentChoice.pos === 0 ? 
            currentChoice.value : 
            activities[step+1]
        )
    const secondChoice = !currentChoice ? 
        activities[step+1] : 
        (
            currentChoice.pos === 1 ? 
            currentChoice.value : 
            activities[step+1]
        )
    

    const AnswerBlock = useMemo(()=>{
        if (desktopMode) {
            return(
                ({children}: PropsWithChildren) => (
                    <BlockPartsTemplate
                    className="quiz-container__action-part__in"
                    classNameMain="quiz-container__action-part"
                    svg={<BlockAnswerAdditional />}
                    svgPosition='start'
                    >
                        {children}
                    </BlockPartsTemplate>
                )
            )
        }

        return (
            ({children}: PropsWithChildren) => (
                <div className="quiz-container__action-part quiz-container__action-part__in quiz-container__action-part--mobile">
                {children}
            </div>
            )
        )
    }, [desktopMode])

    return(
        <Panel
        oneScreenMode
        popout={popout}
        >
            <div
            className='quiz-container'
            >
                <PartedProgress 
                className='quiz-progress'
                parts={10} 
                level={step+1} 
                style={{width:'90%'}}
                />
                <div
                className="quiz-container__image-part"
                >
                    <div className="quiz-card-stack">
                        {
                        Array.from({length: 3}, (_, index) => {
                            const isActiveCard = (2-(step%3)) == index
                            return(
                                <QuizCard 
                                key={index}
                                src={isActiveCard ? images[step] : undefined}
                                style={{
                                    background: !isActiveCard ? cards_colors[index] : undefined
                                }}
                                ref={isActiveCard ? activeCardRef : undefined}
                                />
                            )
                        })
                        }
                    </div>
                </div>
                <AnswerBlock>
                    <div className="quiz-container__action-part__text">
                        {/* {currentChoice?.value && `${quiz_answers[currentChoice?.value]} `}{quiz_questions[step]} */}
                        {quiz_questions[step]}
                    </div>
                    <div className="quiz-container__action-part__actions">
                        <div
                        className='quiz-choice-button'
                        onClick={!inProgress ? ()=>{
                            if(step < 9){
                                answer(firstChoice, 0)
                            }else{
                                toEnd(firstChoice)
                            }
                        } : undefined}
                        >
                            <img src={activities_button_images[firstChoice]} />
                        </div>
                        <div
                        className='quiz-choice-button'
                        onClick={!inProgress ? ()=>{
                            if(step < 9){
                                answer(secondChoice, 1)
                            }else{
                                toEnd(secondChoice)
                            }
                        } : undefined}
                        >
                            <img src={activities_button_images[secondChoice]} />
                        </div>
                        <span
                        className='between-choice-buttons font-bold'
                        >
                            или
                        </span>
                    </div>
                </AnswerBlock>
            </div>
        </Panel>
    )
}