import { HTMLProps } from "react";
import './ProgressBar.css';



interface ProgressBarProps extends HTMLProps<HTMLDivElement> {
    progress?: number;
}


export const ProgressBar = ({
    progress,
    ...rest
}: ProgressBarProps) => {

    return(
        <div {...rest} className="progress-bar__container">
            <div className="progress-bar__item progress-bar__item--background" />
            <div 
            style={{width: `${progress}%`}}
            className="progress-bar__item progress-bar__item--active" />
        </div>
    )
}