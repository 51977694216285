import { HTMLProps, ReactNode } from "react";


interface PaddingDivProps extends HTMLProps<HTMLDivElement> {
    value?: number | string;
    vertical?: number | string;
    horizontal?: number | string;
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
    children?: ReactNode;
}

export const PaddingDiv = ({
    value = 0,
    vertical = 0,
    horizontal = 0,
    top = 0,
    bottom = 0,
    left = 0,
    right = 0,
    children,
    ...rest
}: PaddingDivProps): JSX.Element => {
    return(
        <div
        {...rest}
        style={{
            paddingTop: value || vertical || top,
            paddingBottom: value || vertical || bottom,
            paddingLeft: value || horizontal || left,
            paddingRight: value || horizontal || right,
            ...rest.style
        }}
        >
            {children}
        </div>
    )
}