import { FC } from "react"
import './PreQuizScreen.css';
import { BlockBigLeftTopLongCap, Button, Panel, TextWithHeader } from "../../components";
import { YandexRentHorizontal, YandexRentHorizontalBlack } from "../../icons";
import { BigIconHappySociety } from "../../icons/BigIconHappySociety/BigIconHappySociety";
import { useNavigate, useParams } from "react-router-dom";
import { useStorage } from "../../hooks";
import { activities_cover_image } from "./images";
import { ym } from "../../ym";

export const PreQuizScreen: FC = () => {

    const {userMode: mode, desktopMode} = useStorage()
    const go = useNavigate()

    const next = () => {
        go('/quiz')
        ym('prssLetsGo')
    }

    return(
        <Panel
        oneScreenMode
        className="prequiz-screen"
        >
            <div
            className='preview-screen'
            >
                <div
                className="preview-screen__background"
                >
                    <div
                    className="preview-screen__background__logo"
                    >
                        <YandexRentHorizontalBlack />
                    </div>
                    <div
                    className="preview-screen__background__biglogo"
                    style={{
                        background:`url(${activities_cover_image})`,
                        backgroundPosition: 'center center',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat'
                    }}
                    />
                </div>
                <div
                className="preview-screen__bottom"
                >
                    <BlockBigLeftTopLongCap
                    className='preview-screen__bottom-modal'
                    style={{
                        backgroundColor:'var(--app-color-card-purple)',
                        color:'black',
                        padding: 16,
                        paddingTop: '0px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 16,
                        justifyContent: 'center'
                    }}
                    >
                        <TextWithHeader
                        className='bottom-modal__text'
                        textAlign="left"
                        style={{padding:0}}
                        header="Приятный бонус: дополнительные 10% к первой арендной плате после сдачи квартиры!"
                        >
                            <br/>Пройдите тест, поделитесь результатом и получите специальный промокод для участия в акции<br/><br/>
                        </TextWithHeader>
                        <Button
                        stretched
                        color='purple'
                        onClick={next}
                        >
                            Начать
                        </Button>
                    </BlockBigLeftTopLongCap>
                </div>
            </div>
        </Panel>
    )
}